import React, { Component, Suspense } from 'react';
// import { HashRouter,Switch, Route } from 'react-router-dom';
import { DocCookies, GetLocalData } from '../utils/CoreHelpers';
import { Switch, Route } from 'react-router-dom';
import Loadable from 'react-loadable';
import PropTypes from 'prop-types';
import { AxiosClient } from '../utils/AxiosClient';
import { LocalConfiguration } from '../config/Configurations'
import '../../node_modules/font-awesome/scss/font-awesome.scss';

import Loader from './layout/Loader'
import Aux from "../hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
import routes from "../route";
import SignIn from '../Pages/Authentication/SignIn/SignIn';

const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});

class App extends Component {
      handleLogIn(data) {
        DocCookies.setItem('authToken', data.token, 3600*48, '/', ''); // EXPIRE IN 2 DAYS
        // window.location.reload();
        if(data.userRole === 'BO_ADMIN'){
          window.location.replace('/egames/arena');
        }else if(data.userRole === 'OPERATOR'){
          window.location.replace('/operator/info');
        }else if(data.userRole === 'ARENA_USER'){
          window.location.replace('/arena/info');
        }else if(data.userRole === 'OPERATOR_BRAND'){
          window.location.replace('/operator-brand/info');
        }else if(data.userRole === 'GAME_OPERATOR'){
          window.location.replace('/egames/arena');
        }else if(data.userRole === 'CS_MANAGER'){
          window.location.replace('/egames/player');
        }else if(data.userRole === 'CS_SUPPORT'){
          window.location.replace('/egames/player');
        }else if(data.userRole === 'ODDS_CONTROLLER'){
          window.location.replace('/egames/odds-controller');
        }
      }
    
      handleLogOut(e) {
        e.preventDefault();
        DocCookies.removeItem('authToken' , '/');
        window.location.replace('/');
      }
      
      getOperatorCodeFilter(){
        const user = JSON.parse(GetLocalData('userInfo', {}));
        if(user && user.info){
          return [
            {
              'fieldName': 'operatorCode',
              'fieldValue1': user.info.operatorCode,
              'type': 'string',
              'operand': 'eq'
            }
          ];
        }else{
          return [];
        }
      }
      getOperatorCodeFilterGeneric(){
        const user = JSON.parse(GetLocalData('userInfo', {}));
        if(user){
          return [
            {
              'fieldName': 'operatorCode',
              'fieldValue1': user.operatorCode,
              'type': 'string',
              'operand': 'eq'
            }
          ];
        }else{
          return [];
        }
      }
      getArenaCodeFilter(){
        const user1 = JSON.parse(GetLocalData('userInfo', {}));
        if(user1 && user1.info){
          return [
            {
              'fieldName': 'arenaCode',
              'fieldValue1': user1.info.arenaCode,
              'type': 'string',
              'operand': 'eq'
            }
          ];
        }else{
          return [];
        }
      }
      getOperatorBrandCodeFilter(){
        const user1 = JSON.parse(GetLocalData('userInfo', {}));
        if(user1 && user1.info){
          return [
            {
              'fieldName': 'brandCode',
              'fieldValue1': user1.info.brandCode,
              'type': 'string',
              'operand': 'eq'
            }
          ];
        }else{
          return [];
        }
      }
      static childContextTypes = {
        axios: PropTypes.object.isRequired,
        axiosEsabong: PropTypes.object.isRequired,
        handleLogIn: PropTypes.func,
        handleLogOut: PropTypes.func,
        getOperatorCodeFilter: PropTypes.func,
        getOperatorCodeFilterGeneric: PropTypes.func,
        getArenaCodeFilter: PropTypes.func,
        getOperatorBrandCodeFilter: PropTypes.func,
        currentUser: PropTypes.object
      }
    
      getChildContext() {
        let currUserObject = {};
        if (Object.keys(GetLocalData('userInfo', {})).length === 0) {
          currUserObject = {};
        } else {
          currUserObject = JSON.parse(GetLocalData('userInfo', {}));
        }
        return {
          handleLogIn: this.handleLogIn.bind(this),
          handleLogOut: this.handleLogOut.bind(this),
          getOperatorCodeFilter: this.getOperatorCodeFilter.bind(this),
          getOperatorCodeFilterGeneric: this.getOperatorCodeFilterGeneric.bind(this),
          getArenaCodeFilter: this.getArenaCodeFilter.bind(this),
          getOperatorBrandCodeFilter: this.getOperatorBrandCodeFilter.bind(this),
          axios: AxiosClient(DocCookies.getItem('authToken'), LocalConfiguration.ApiUserManagementEndPoint()),
          axiosEsabong: AxiosClient(DocCookies.getItem('authToken'), LocalConfiguration.ApiGamePlayEndPoint()),
          currentUser: currUserObject
        }
      }

      render() {
        if(!DocCookies.getItem('authToken')){
          return <SignIn/>
        }
        return (
            <Aux>
                <ScrollToTop>
                    <Suspense fallback={<Loader/>}>
                        <Switch>
                            {routes.map((route, index) => {
                              return (route.component) ? (
                                  <Route
                                      key={index}
                                      path={route.path}
                                      exact={route.exact}
                                      name={route.name}
                                      render={props => (
                                        <route.component {...props} />
                                      )} />
                              ) : (null);
                            })}
                            <Route path="/" component={AdminLayout} />
                        </Switch>
                    </Suspense>
                </ScrollToTop>
            </Aux>
        );
    }
}

export default App;
