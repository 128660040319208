import axios from 'axios';

export const AxiosClient = (token = null, baseUrl = null) => {
    const defaultOptions = {
        headers: {
            'x-token-key': token,
        },
    };
    return {
        get: (url, options = {}) => axios.get(baseUrl+url, { ...defaultOptions, ...options }),
        post: (url, data, options = {}) => axios.post(baseUrl+url, data, { ...defaultOptions, ...options }),
        put: (url, data, options = {}) => axios.put(baseUrl+url, data, { ...defaultOptions, ...options }),
        delete: (url, options = {}) => axios.delete(baseUrl+url, { ...defaultOptions, ...options }),
    };
};