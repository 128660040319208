export const LocalConfiguration = {
	// for user management back office api
	ApiUserManagementEndPoint: function(){
		const EndPoints = {
			'dev_ApiEndPoint': 'https://gameplay-user-api.citronsolutions.com',
			'stag_ApiEndPoint': 'https://gameplay-user-api.citronsolutions.com',
			'prod_ApiEndPoint': 'https://gameplay-user-api.citronsolutions.com'
		};
		return EndPoints[`${process.env.REACT_APP_ENDPOINT_PREFIX}_ApiEndPoint`];
	},
	//for gameplay api
	ApiGamePlayEndPoint: function(){
		const EndPoints = {
			'dev_ApiEndPoint': 'https://gameplay-api.citronsolutions.com',
			'stag_ApiEndPoint': 'https://gameplay-api.citronsolutions.com',
			'prod_ApiEndPoint': 'https://gameplay-api.citronsolutions.com',
		};
		return EndPoints[`${process.env.REACT_APP_ENDPOINT_PREFIX}_ApiEndPoint`];
	},
	// ApiBetTransactionEndPoint: function(){
	// 	const EndPoints = {
	// 		'dev_ApiEndPoint': 'https://bp-bettransaction-api.citronsolutions.com',
	// 		'stag_ApiEndPoint': 'https://bp-bettransaction-api.citronsolutions.com',
	// 		'prod_ApiEndPoint': 'https://bp-bettransaction-api.citronsolutions.com',
	// 	};
	// 	return EndPoints[`${process.env.REACT_APP_ENDPOINT_PREFIX}_ApiEndPoint`];
	// }
}