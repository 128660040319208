import React from 'react';
// import {NavLink} from 'react-router-dom';

import { LocalConfiguration } from '../../../config/Configurations';
import { SetLocalData } from '../../../utils/CoreHelpers';
import { Form, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import axios from 'axios';

import './../../../assets/scss/style.scss';
import Aux from "../../../hoc/_Aux";
// import Breadcrumb from "../../../App/layout/AdminLayout/Breadcrumb";

import AuthlogoImgMain from '../../../../src/assets/images/auth-logo.png';
import logoImgMain from '../../../../src/assets/images/logo.png';


class SignUp extends React.Component {

    static contextTypes = {
        handleLogIn: PropTypes.func.isRequired
    };

    state = {
        GeneratedKey: '',
        loginData: {
            UserName: '',
            Password: '',
        },
        errorMsg: '',
    }
    
    handleInputChange = f => e => {
        this.setState({
            loginData: { ...this.state.loginData, [f]: e.target.value }
        })
    }
    
    submitLoginForm = e => {
        e.preventDefault();
        const { UserName, Password } =  this.state.loginData;
        if (UserName.length === 0 || Password.length === 0) {
            this.setState({ errorMsg: 'Username and Password is required.' })
        } else {
            // LOGIN AND GET USER INFO RIGHT AWAY
            axios.get(LocalConfiguration.ApiUserManagementEndPoint()+'/bo/user/login?userName='+encodeURI(UserName+'&password='+Password)).then(({data}) => {
                if(data.userRole === 'OPERATOR'){
                    axios.get(LocalConfiguration.ApiGamePlayEndPoint()+'/operator/info', { headers: { 'x-token-key': data.token } }).then((response) => {
                        SetLocalData('userInfo', JSON.stringify({userName: data.userName, userRole: data.userRole, info: response.data}));
                        this.context.handleLogIn(data);
                    }).catch((error) => {
                        this.setState({ errorMsg: 'Unable to login, Please contact your System Administrator.' });
                    });
                } else if(data.userRole === 'ARENA_USER') {
                    axios.get(LocalConfiguration.ApiGamePlayEndPoint()+'/arena/info', { headers: { 'x-token-key': data.token } }).then((response) => {
                        SetLocalData('userInfo', JSON.stringify({userName: data.userName, userRole: data.userRole, info: response.data}));
                        this.context.handleLogIn(data);
                    }).catch((error) => {
                        this.setState({ errorMsg: 'Unable to login, Please contact your System Administrator.' });
                    });
                }else if(data.userRole === 'OPERATOR_BRAND') {
                    axios.get(LocalConfiguration.ApiGamePlayEndPoint()+'/operator-brand/info', { headers: { 'x-token-key': data.token } }).then((response) => {
                        SetLocalData('userInfo', JSON.stringify({userName: data.userName, userRole: data.userRole, info: response.data}));
                        this.context.handleLogIn(data);
                    }).catch((error) => {
                        this.setState({ errorMsg: 'Unable to login, Please contact your System Administrator.' });
                    });
                }else{
                    SetLocalData('userInfo', JSON.stringify({userName: data.userName, userRole: data.userRole, operatorId: data.operatorId, operatorCode: data.operatorCode,
                            operatorBrandId: data.operatorBrandId, operatorBrandCode: data. operatorBrandCode, arenaId: data.arenaId,
                            arenaCode: data.arenaCode, currency: data.currency
                    }));
                    //getting of currencies
                    axios.get(LocalConfiguration.ApiGamePlayEndPoint()+'/game-point-rate', { headers: { 'x-token-key': data.token } }).then((response) => {
                        SetLocalData('gamePointRate', JSON.stringify({data:response.data}));
                        this.context.handleLogIn(data);
                    }).catch((error) => {
                        this.setState({ errorMsg: 'Unable to login, Please contact your System Administrator.' });
                    });
                }
            }).catch((error) => {
                this.setState({ errorMsg: 'Unable to login, Please contact your System Administrator.' });
            });
        }
    }

    render () {
        const { errorMsg } = this.state
        return(
            <Aux>
                {/* <Breadcrumb/> */}
                <div className="auth-wrapper">
                    <div className="auth-content">
                        <div className="auth-bg">
                            <span className="r"/>
                            <span className="r s"/>
                            <span className="r s"/>
                            <span className="r"/>
                        </div>
                        <div className="card">
                            <div className="card-body text-center">
                                <div className="mb-3">
                                    <img className="auth-logo-img" src={AuthlogoImgMain} alt="GamePlay"/>
                                </div>
                                <div className="mb-4">
                                    <img className="logo-img" src={logoImgMain} alt="GamePlay"/>
                                </div>
                                {/* <h3 className="mb-4">Login</h3> */}
                                <Form onSubmit={this.submitLoginForm.bind(this)} autoComplete="off">
                                    <div className="input-group mb-3">
                                        <Input type="username" className="form-control" placeholder="Username" autoComplete="off" 
                                        aria-describedby="validationTooltipUsernamePrepend" required 
                                        onChange={this.handleInputChange('UserName')}/>
                                    </div>
                                    <div className="input-group mb-1">
                                        <Input type="password" className="form-control" placeholder="Password" autoComplete="off"
                                        aria-describedby="validationTooltipPasswordPrepend" required 
                                        onChange={this.handleInputChange('Password')}/>
                                    </div>
                                    <div className="form-group text-left">
                                        {/* <div className="checkbox checkbox-fill d-inline">
                                            <input type="checkbox" name="checkbox-fill-1" id="checkbox-fill-a1" />
                                                <label htmlFor="checkbox-fill-a1" className="cr"> Save credentials</label>
                                        </div> */}
                                        { 
                                                errorMsg && <p className="text-danger"><small>{errorMsg}</small></p>
                                            }
                                    </div>
                                    <button className="btn btn-primary shadow-2 mb-4">Login</button>
                                </Form>
                                {/*<p className="mb-2 text-muted">Forgot password? <NavLink to="/auth/reset-password-1">Reset</NavLink></p>*/}
                                {/* <p className="mb-0 text-muted">Don’t have an account? <NavLink to="/auth/signup-1">Signup</NavLink></p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Aux>
        );
    }
}

export default SignUp;