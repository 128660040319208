/** @module CoreHelpers */
/**
 * Manage Cookies
 * @namespace DocCookies
 * @author Kim
 */
export const DocCookies = {
	/* eslint-disable no-useless-escape */

	/**
	* Get Cookie Item
	* @param {String} sKey Key
	* @returns {String} value
	*/
	getItem: function (sKey) {
		return decodeURIComponent(document.cookie.replace(new RegExp('(?:(?:^|.*;)\\s*' + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=\\s*([^;]*).*$)|^.*$'), '$1')) || null
	},

	/**
	* Set Cookie Item
	* @param {String} sKey Key
	* @param {String} sValue Key
	* @param {String} vEnd Key
	* @param {String} sPath Key
	* @param {String} sDomain Key
	* @param {String} bSecure Key
	* @returns {Boolean} True/False
	*/
	setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
		if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false }
		var sExpires = ''
		if (vEnd) {
			switch (vEnd.constructor) {
			case Number:
				sExpires = vEnd === Infinity ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT' : '; max-age=' + vEnd
				break
			case String:
				sExpires = '; expires=' + vEnd
				break
			case Date:
				sExpires = '; expires=' + vEnd.toUTCString()
				break
			default:
				break
			}
		}
		document.cookie = encodeURIComponent(sKey) + '=' + encodeURIComponent(sValue) + sExpires + (sDomain ? '; domain=' + sDomain : '') + (sPath ? '; path=' + sPath : '') + (bSecure ? '; secure' : '')
		return true
	},
	/**
	* Remove Cookie Item
	* @param {String} sKey Key
	* @param {String} sPath Path
	* @param {String} sDomain Domain
	* @returns {Boolean} True/False
	*/
	removeItem: function (sKey, sPath, sDomain) {
		if (!sKey || !this.hasItem(sKey)) { return false }
		document.cookie = encodeURIComponent(sKey) + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' + ( sDomain ? '; domain=' + sDomain : '') + ( sPath ? '; path=' + sPath : '')
		return true
	},
	/**
	* Check Cookie Existence
	* @param {String} sKey Key
	* @returns {Boolean} True/False
	*/
	hasItem: function (sKey) {
		return (new RegExp('(?:^|;\\s*)' + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=')).test(document.cookie)
	},
	/* eslint-enable no-useless-escape */
}

/**
 * Get LocalStorage data by Key
 * 
 * @param {String} key Key to look for
 * @param {String} defaultValue Default Value if Key is non-existent
 * @returns {String} Value
 * @author Kim
 */
export function GetLocalData(key, defaultValue) {
	var item = localStorage.getItem(key)
	return (item) ? item : defaultValue
}

/**
 * Set LocalStorage data by Key
 * 
 * @param {String} key Key to Set
 * @param {String} value Value to Set
 * @returns {String} Value
 * @author Kim
 */
export function SetLocalData(key, value) {
	localStorage.setItem(key, value)
	return value
}

/**
 * Remove LocalStorage data by Key
 * 
 * @param {String} key Key to Remove
 * @returns {null} Null
 * @author Kim
 */
export function RemoveLocalData(key) {
	localStorage.removeItem(key)
	return
}

/**
 * Format Currency for Display
 * 
 * @param {String} value Value
 * @param {String} scale Scale
 * @returns {String} Formatted value
 * @author Kim
 */
export function FormatCurrency(value, scale) {
	let scaleInp = scale ? scale : 3
	return Number(value).toLocaleString(undefined, { minimumFractionDigits: scaleInp })
}

/**
 * Check if passed object is really an Object
 * 
 * @param {Object} obj Sample object
 * @returns {Boolean} True or False
 * @author Jonathan
 */
export function isObject(obj) {
	return (!!obj) && (obj.constructor === Object)
}

/**
 * Check if passed object is empty
 * 
 * @param {Object} obj Sample object
 * @returns {Boolean} True or False
 * @author Jonathan
 */
export function isObjectEmpty(obj) {
	return (Object.keys(obj).length === 0)
}

/**
 * Convert camel-cased string to separate words
 * 
 * @param {String} camelCase Sample string
 * @returns {String} Converted string
 * @author Kim
 */
export function camelCaseToWords(camelCase) {
	return camelCase.replace(/([A-Z])/g, function(match) {
		return ' ' + match
	}).replace(/^./, function(match) {
		return match.toUpperCase()
	})
}

/**
 * Return formatted Currency
 * 
 * @param {String} unformatted number (int)
 * @returns {String} currency formatted string
 * @author Kim
 */
export const numberWithCommas = (x, decimal) => {
	// TODO: MAKE decimal BE PART OF THE REPLACE
  return x.toString().replace(/\B(?=(\d{4})+(?!\d))/g, ",");
}

/**
 * Return re-indexed array of html element
 * 
 * @param {[]} Array
 * @returns {[]} reindexed Array
 * @author Kim
 */
export const reOrderIndexes = (items, draggedElement, catcherElement) => { 
	const newItems = items.slice(0); // COPY
	newItems.splice(catcherElement, 0, newItems.splice(draggedElement, 1)[0]);
	return newItems; 
}

/**
 * Return properly mapped array required by Select2
 * 
 * @param {rawArray} Array
 * @param {fieldValue} String
 * @param {fieldLabel} String
 * @returns {[]} reindexed Array
 * @author Kim
 */
export const arrayToSelectOptions = (rawArray, fieldValue, fieldLabel) => {
	return rawArray.map(function(arr, i){
		return {
			value: arr[fieldValue],
			label: arr[fieldLabel]
		};
	});
}

/**
 * Return object in proper format that is expecting by Select2 as default option
 * 
 * @param {rawArray} Array
 * @param {fieldValue} String
 * @param {fieldLabel} String
 * @returns {}
 * @author Kim
 */
export const getSelect2DefaultValue = (rawArray, fieldValue) => {
	let option = rawArray.filter(function(arr){
		return (arr.value === fieldValue);
	});
	if(option.length > 0){
		return {value: option[0].value, label: option[0].label};
	}else{
		return null;
	}
}

/**
 * Return concatenated value that is expecting by Select as default option
 * 
 * @param {rawArray} Array
 * @param {fieldValue} String
 * @param {fieldLabel1} String
 * @param {fieldLabel2} String
 * @returns {}
 * @author Mel
 */
export const arrayToSelectOptionsMerge = (rawArray, fieldValue, fieldLabel1, fieldLabel2) => {
	return rawArray.map(function(arr, i){
		return {
			value: arr[fieldValue],
			label: arr[fieldLabel1] + ' ' + arr[fieldLabel2]
		};
	});
}

/**
 * Return URLencoded from Object
 * 
 * @param {data} Object
 * @returns urlEncoded Parameters
 * @author Kim
 */
export const objectToUrlEncodedParameters = (data) => {
	return Object.keys(data).map(key => `${key}=${encodeURIComponent(data[key])}`).join('&');
}


/**
 * Return new Arrays of fields that has error
 * 
 * @param {data} Object
 * @returns Array
 * @author Kim
 */
export const checkRequiredFeilds = (state) => {
	let errors = [];
	Object.keys(state).forEach(function(key, index) {
		if(state[key] === null || state[key] === undefined || state[key] === ''){
			errors.push(key);
		}
	});
	return errors;
}

/**
 * Return Boolean
 * 
 * @param {arr}
 * @returns Array
 * @author Kim
 */
export const isInErrorList = (arr, fieldName) => {
	return (arr.indexOf(fieldName) !== -1);
}

/**
 * Return String in Proper Case
 * 
 * @param {String}
 * @returns String
 * @author Kim
 */
export const toTitleCase = (str) => {
	return str.replace(/\w\S*/g, function(txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
}

export const getUrlParameter = (urlString, target) => {
	return new URLSearchParams(urlString).get(target)
}

export const generateRegla = (history) => {
	if(history.length === 0){return []}
	let reglaArray = [];
	let rowCol = [];
	history.forEach((m) => {
		const winningCorner = (m.matchStatus === 'CANCELED') ? 'CANCELLED' : m.matchWinningCorner;
		rowCol.push(winningCorner);
		if(rowCol.length >= 9){
			reglaArray.push(rowCol);
			rowCol = [];
		}
	});

	// PUSH LEFT OUT INDEXES
	if(rowCol.length > 0){
		if(rowCol.length < 9){
			const lastFightNo = parseInt(history[history.length - 1].fightNumber) + 1;
			const addHolder = (9 - rowCol.length);
			for(let itr = 0; itr < addHolder; itr++){
				rowCol.push(lastFightNo + itr);
			}
		}
		reglaArray.push(rowCol);
	}else{
		const lastFightNo = parseInt(history[history.length - 1].fightNumber) + 1;
		const addHolder = 9;
		for(let itr = 0; itr < addHolder; itr++){
			rowCol.push(lastFightNo + itr);
		}
		reglaArray.push(rowCol);
	}
	return reglaArray;
}

export const jsonToCSVfile = (response, csvHeader) => {
	exportCSVFile(csvHeader.header, response.data.data, csvHeader.fileName);
	function exportCSVFile(headers, items, fileTitle) {
	    var jsonObject = JSON.stringify(items);
	    var csv = convertToCSV(jsonObject, headers);
	    var exportedFilenmae = fileTitle + ' '+ (new Date().toLocaleString().replace(',','')) +'.csv' || 'export.csv';
	    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
	    if (navigator.msSaveBlob) { // IE 10+
	        navigator.msSaveBlob(blob, exportedFilenmae);
	    } else {
	        var link = document.createElement("a");
	        if (link.download !== undefined) { // feature detection
	            // Browsers that support HTML5 download attribute
	            var url = URL.createObjectURL(blob);
	            link.setAttribute("href", url);
	            link.setAttribute("download", exportedFilenmae);
	            link.style.visibility = 'hidden';
	            document.body.appendChild(link);
	            link.click();
	            document.body.removeChild(link);
	        }
	    }
	}
	function convertToCSV(objArray, csvheaders) {
	    var array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
		
	    var str = '';
	    // HEADER
	    var headerLine = '';
	    csvheaders.forEach((h) => {
	    	headerLine += h.title + ',';
	    });
	    str += headerLine + '\r\n';
	    // ITEMS
	    for (var i = 0; i < array.length; i++) {
	        var line = '';
	        for(var index = 0; index < csvheaders.length; index++){
	            if (line !== '') line += ',';
	            let rawLinePart = (array[i][csvheaders[index].fld] || '' || null);
				if (rawLinePart === '' || rawLinePart === null) rawLinePart = '-';
				if (rawLinePart === 0) rawLinePart = 0;
				line += (csvheaders[index].dirty) ? rawLinePart.replace(/,/g, '') : rawLinePart;
	        }
	        str += line + '\r\n';
	    }
	    return str;
	}
}

export const currencyOptions = () => {
    return {
    	data: [
	        {
	            key: 'CNY',
	            value: 'Chinese Yuan'
	        },
	        {
	            key: 'USD',
	            value: 'U.S. Dollar'
	        },
	        {
	            key: 'KRW',
	            value: 'Korean Won'
	        },
	        {
	            key: 'IDR',
	            value: 'Indonesian Ruppe'
	        },
			{
	            key: 'IDRK',
	            value: 'IDR (Devalued)'
	        },
	        {
	            key: 'MYR',
	            value: 'Malaysian Ringgit'
	        },
			{
	            key: 'VND',
	            value: 'Vietnamese Dong'
	        },
			{
	            key: 'VNDK',
	            value: 'VND (Devalued)'
	        },
			{
	            key: 'NTD',
	            value: 'New Taiwan Dollar'
	        },
			{
	            key: 'THB',
	            value: 'Thai Baht'
	        },
			{
	            key: 'JPY',
	            value: 'Japan Yen'
	        },
			{
	            key: 'SGD',
	            value: 'Singaporean Dollars'
	        },
			{
	            key: 'HKD',
	            value: 'Hong Kong Dollars'
	        },
			{
				key: 'PEN',
				value: 'Sol'
			  },
			  {
				key: 'COP',
				value: 'Colombian Peso' 
			  },
			  {
				key: 'CUP',
				value: 'Cuban Peso' 
			  },
			  {
				key: 'PAB',
				value: 'Balboa' 
			  },
			  {
				key: 'USD',
				value: 'US Dollar'
			  },
			  {
				key: 'CRC',
				value: 'Costa Rican Colon' 
			  },
			  {
				key: 'CLP',
				value: 'Chilean Peso' 
			  },
			  {
				key: 'BRL',
				value: 'Brazilian Real' 
			  },
			  {
				key: 'MXN',
				value: 'Mexican Peso' 
			  },
			  {
				key: 'DOP',
				value: 'Dominican Peso' 
			  },
			  {
				key: 'NIO',
				value: 'Cordoba Oro' 
			  },
			  {
				key: 'GTQ',
				value: 'Quetzal' 
			  },
			  {
				key: 'BOB',
				value: 'Boliviano' 
			  },
			  {
				key: 'VEF',
				value: 'Bolivar' 
			  },
			  {
				key: 'PYG',
				value: 'Guarani' 
			  },
			  {
				key: 'UYU',
				value: 'Peso Uruguayo' 
			  },
			  {
				key: 'ARS',
				value: 'Argentine Peso' 
			  },
			  {
				key: 'SVC',
				value: 'El Savador Colon' 
			  },
			  {
				key: 'HNL',
				value: 'Lempira' 
			  },
			  {
				key: 'HTG',
				value: 'Gourde' 
			  },
			  {
				key: 'INR',
				value: 'Indian Rupee' 
			  }
	    ]
	}
}
export const currencyCodeOptions = () => {
    return {
    	data: [
	        // {
	        //     key: 'PHP',
	        //     value: 'PHP'
	        // },
			{
	            key: '',
	            value: 'N/A'
	        },
	        {
	            key: 'CNY',
	            value: 'CNY'
	        },
	        {
	            key: 'USD',
	            value: 'USD'
	        },
	        {
	            key: 'KRW',
	            value: 'KRW'
	        },
	        {
	            key: 'IDR',
	            value: 'IDR'
	        },
	        {
	            key: 'MYR',
	            value: 'MYR'
	        },
			{
	            key: 'VND',
	            value: 'VND'
	        },
			{
	            key: 'NTD',
	            value: 'NTD'
	        },
			{
	            key: 'THB',
	            value: 'THB'
	        },
			{
	            key: 'JPY',
	            value: 'JPY'
	        },
			{
	            key: 'SGD',
	            value: 'SGD'
	        },
			{
	            key: 'HKD',
	            value: 'HKD'
	        },
			{
				label: 'PEN',
				value: 'PEN'
			  },
			  {
				label: 'COP',
				value: 'COP' 
			  },
			  {
				label: 'CUP',
				value: 'CUP' 
			  },
			  {
				label: 'PAB',
				value: 'PAB' 
			  },
			  {
				label: 'USD',
				value: 'USD'
			  },
			  {
				label: 'CRC',
				value: 'CRC' 
			  },
			  {
				label: 'CLP',
				value: 'CLP' 
			  },
			  {
				label: 'BRL',
				value: 'BRL' 
			  },
			  {
				label: 'MXN',
				value: 'MXN' 
			  },
			  {
				label: 'DOP',
				value: 'DOP' 
			  },
			  {
				label: 'NIO',
				value: 'NIO' 
			  },
			  {
				label: 'GTQ',
				value: 'GTQ' 
			  },
			  {
				label: 'BOB',
				value: 'BOB' 
			  },
			  {
				label: 'VEF',
				value: 'VEF' 
			  },
			  {
				label: 'PYG',
				value: 'PYG' 
			  },
			  {
				label: 'UYU',
				value: 'UYU' 
			  },
			  {
				label: 'ARS',
				value: 'ARS' 
			  },
			  {
				label: 'SVC',
				value: 'SVC' 
			  },
			  {
				label: 'HNL',
				value: 'HNL' 
			  },
			  {
				label: 'HTG',
				value: 'HTG' 
			  },
			  {
				label: 'INR',
				value: 'INR' 
			  }
	    ]
	}
}
export const languageOptions = () => {
    return {
    	data: [
	        {
	            key: 'EN',
	            value: 'English (en)'
	        },
	        {
	            key: 'ID',
	            value: 'Indonesian (id)'
	        },
	        {
	            key: 'KO',
	            value: 'Korean (ko)'
	        },
	        {
	            key: 'JA',
	            value: 'Nihongo (ja)'
	        },
	        {
	            key: 'ZH-HANS',
	            value: 'Chinese (zh-Hans)'
	        }
	    ]
	}
}

export const fundType = () => {
    return {
    	data: [
	        {
	            key: 'FUND_IN',
	            value: 'Fund In'
	        },
	        {
	            key: 'FUND_OUT',
	            value: 'Fund Out'
	        },
	        // {
	        //     key: 'DEBIT_ADJUSTMENT',
	        //     value: 'Debit Adjustment'
	        // },
	        // {
	        //     key: 'CREDIT_ADJUSTMENT',
	        //     value: 'Credit Adjustment'
	        // }
	    ]
	}
}
export const fundTransactionType = () => {
    return {
    	data: [
	        {
	            key: 'FUND_IN',
	            value: 'Fund In'
	        },
	        {
	            key: 'FUND_OUT',
	            value: 'Fund Out'
	        },
			{
	            key: 'COMMISSION',
	            value: 'Commission'
	        },
			// {
	        //     key: 'WIN',
	        //     value: 'Win'
	        // },
			// {
	        //     key: 'BET',
	        //     value: 'Bet'
	        // },
			// {
	        //     key: 'ADJ_CREDIT',
	        //     value: 'Credit Adjustment'
	        // },
			// {
	        //     key: 'ADJ_DEBIT',
	        //     value: 'Debit Adjustment'
	        // },
	        // {
	        //     key: 'DEBIT_ADJUSTMENT',
	        //     value: 'Debit Adjustment'
	        // },
	        // {
	        //     key: 'CREDIT_ADJUSTMENT',
	        //     value: 'Credit Adjustment'
	        // }
	    ]
	}
}
export const fundTypeCmDm = () => {
    return {
    	data: [
	        {
	            key: 'DEBIT_ADJUSTMENT',
	            value: 'Debit Adjustment'
	        },
	        {
	            key: 'CREDIT_ADJUSTMENT',
	            value: 'Credit Adjustment'
	        }
	    ]
	}
}
export const betResultStatus = () => {
    return {
    	data: [
			// {
	        //     value: 'WIN',
	        //     label: 'WIN'
	        // },
			// {
	        //     value: 'LOSE',
	        //     label: 'LOSE'
	        // },
	        {
	            value: 'DRAW',
	            label: 'DRAW'
	        },
	        {
	            value: 'CANCELED',
	            label: 'CANCELED'
	        },
	        // {
	        //     key: 'DEBIT_ADJUSTMENT',
	        //     value: 'Debit Adjustment'
	        // },
	        // {
	        //     key: 'CREDIT_ADJUSTMENT',
	        //     value: 'Credit Adjustment'
	        // }
	    ]
	}
}
export const betStatus = () => {
    return {
    	data: [
			{
	            key: 'WIN',
	            value: 'WIN'
	        },
			{
	            key: 'LOSE',
	            value: 'LOSE'
	        },
	        {
	            key: 'DRAW',
	            value: 'DRAW'
	        },
	        {
	            key: 'CANCELED',
	            value: 'CANCELLED'
	        },
	        {
	            key: 'PENDING',
	            value: 'PENDING'
	        },
			{
	            key: 'VOIDED',
	            value: 'VOID'
	        },
	        // {
	        //     key: 'CREDIT_ADJUSTMENT',
	        //     value: 'Credit Adjustment'
	        // }
	    ]
	}
}
export const userRole = () => {
    return {
    	data: [
			{ key: 'ADMIN', value: 'BO_ADMIN' },
			{ key: 'USER', value: 'BO_USER' },
			{ key: 'GAME OPERATOR', value: 'GAME_OPERATOR' },
			{ key: 'ODDS CONTROLLER', value: 'ODDS_CONTROLLER' },
			{ key: 'CS MANAGER', value: 'CS_MANAGER' },
			{ key: 'CS SUPPORT', value: 'CS_SUPPORT' }
		]
	}
}

export const userRoleQuickSearch = () => {
    return {
    	data: [
			{ key: 'ADMIN', value: 'BO_ADMIN' },
			{ key: 'USER', value: 'BO_USER' },
			{ key: 'OPERATOR', value: 'OPERATOR' },
			{ key: 'BRAND', value: 'OPERATOR_BRAND' },
			{ key: 'GAME OPERATOR', value: 'GAME_OPERATOR' },
			{ key: 'ODDS CONTROLLER', value: 'ODDS_CONTROLLER' },
			{ key: 'CS MANAGER', value: 'CS_MANAGER' },
			{ key: 'CS SUPPORT', value: 'CS_SUPPORT' }
		]
	}
}