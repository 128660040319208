import { FormatCurrency, GetLocalData } from './utils/CoreHelpers';
let userInfo = {};
        if (Object.keys(GetLocalData('userInfo', {})).length === 0) {
            userInfo = {};
        } else {
            userInfo = JSON.parse(GetLocalData('userInfo', {}));
        }
let defPath = "";
if(userInfo) {
    switch(userInfo.userRole) {
        case "ARENA_USER":
            defPath = "/arena/info";
            break;
        case "OPERATOR":
            defPath = "/operator/info";   
            break; 
        case "OPERATOR_BRAND":
            defPath = "/operator-brand/info"; 
            break; 
        case "GAME_OPERATOR":
            defPath = "/egames/arena"; 
            break;  
        case "CS_MANAGER":
            defPath = "/egames/player"; 
            break;      
        case "CS_SUPPORT":
            defPath = "/egames/player"; 
            break; 
        case "ODDS_CONTROLLER":
            defPath = "/egames/odds-controller"; 
            break;                                
        default:
            defPath = "/egames/bet";       
    }
}
export default {
    defaultPath: defPath,
    layout: 'vertical',
    preLayout: null,
};
